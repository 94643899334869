<template>
  <div v-if="dataPdf && schoolDetail" id="pdfDownloadContainerStaff">
    <div id="headerDocStaff" class="reportCardHeader">
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <img :src="schoolDetail.about.path_landscape" width="150px" />
            </th>
            <th colspan="10">
              <div style="font-size: 20px">
                {{ schoolDetail.about.foundation }}
              </div>
              <div style="font-size: 22px">
                <b>{{ schoolDetail.about.name }}</b>
              </div>
              <div>{{ schoolDetail.about.address }}</div>
              <div>Telepon: {{ schoolDetail.about.phone }}</div>
              <div>Email: {{ schoolDetail.about.email }}</div>
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div style="margin: 20px 0; border-bottom: 1px solid black"></div>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Data Diri
          </th>
        </tr>
        <tr>
          <td colspan="4" rowspan="4">
            <img :src="dataPdf.data_guru.foto" width="60px" height="60px" />
          </td>
          <td colspan="8">
            <div class="textWrapper">
              <div class="title-pdf">Nama Lengkap</div>
              <div class="text-pdf">{{ dataPdf.data_guru.nama }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Tempat Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.data_guru.tempat_lahir }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Tanggal Lahir</div>
              <div class="text-pdf">
                {{ dataPdf.data_guru.tanggal_lahir }}
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Jenis Kelamin</div>
              <div class="text-pdf">
                {{ dataPdf.data_guru.jenis_kelamin }}
              </div>
            </div>
          </td>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Agama</div>
              <div class="text-pdf">{{ dataPdf.data_guru.agama }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <div class="textWrapper">
              <div class="title-pdf">Nomor Handphone</div>
              <div class="text-pdf">{{ dataPdf.data_guru.telepon }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Alamat
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Alamat</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.alamat }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Provinsi</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.provinsi }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kota / Kabupaten</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.kota }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Kode Pos</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.kode_pos }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Negara</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.negara }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Telepon Rumah</div>
              <div class="text-pdf">
                {{ dataPdf.alamat_guru.telepon_rumah }}
              </div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Email</div>
              <div class="text-pdf">{{ dataPdf.alamat_guru.email }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="html2pdf__page-break"></div>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Jenjang SD
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Sekolah</div>
              <div class="text-pdf">{{ dataPdf.SD.nama_sekolah }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Masuk</div>
              <div class="text-pdf">{{ dataPdf.SD.tahun_masuk }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Lulus</div>
              <div class="text-pdf">{{ dataPdf.SD.tahun_lulus }}</div>
            </div>
          </td>
        </tr>
        <td colspan="12">
          <div v-for="(file, index) in dataPdf.SD.dokumen" :key="index">
            {{ downloadDoc(file) }}
          </div>
        </td>
      </tbody>
    </table>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Jenjang SMP
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Sekolah</div>
              <div class="text-pdf">{{ dataPdf.SMP.nama_sekolah }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Masuk</div>
              <div class="text-pdf">{{ dataPdf.SMP.tahun_masuk }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Lulus</div>
              <div class="text-pdf">{{ dataPdf.SMP.tahun_lulus }}</div>
            </div>
          </td>
        </tr>
        <td colspan="12">
          <div v-for="(file, index) in dataPdf.SMP.dokumen" :key="index">
            {{ downloadDoc(file) }}
          </div>
        </td>
      </tbody>
    </table>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Jenjang SMA
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Nama Sekolah</div>
              <div class="text-pdf">{{ dataPdf.SMA.nama_sekolah }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Masuk</div>
              <div class="text-pdf">{{ dataPdf.SMA.tahun_masuk }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Lulus</div>
              <div class="text-pdf">{{ dataPdf.SMA.tahun_lulus }}</div>
            </div>
          </td>
        </tr>
        <td colspan="12">
          <div v-for="(file, index) in dataPdf.SMA.dokumen" :key="index">
            {{ downloadDoc(file) }}
          </div>
        </td>
      </tbody>
    </table>

    <table style="margin-bottom: 20px">
      <thead>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Perguruan Tinggi
          </th>
        </tr>
      </thead>
      <tbody v-for="(d, i) in dataPdf.strata" :key="i">
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Perguruan Tinggi</div>
              <div class="text-pdf">{{ d.jenjang }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Asal Institusi Pendidikan</div>
              <div class="text-pdf">{{ d.asal_institusi }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Ijazah</div>
              <div class="text-pdf">{{ d.nomor_ijazah }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Akreditas</div>
              <div class="text-pdf">{{ d.akreditas }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nilai IPK</div>
              <div class="text-pdf">{{ d.nilai_ipk }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Masuk</div>
              <div class="text-pdf">{{ d.tahun_masuk }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Lulus</div>
              <div class="text-pdf">{{ d.tahun_lulus }}</div>
            </div>
          </td>
        </tr>
        <td colspan="12">
          <div v-for="(file, index) in d.dokumen" :key="index">
            {{ downloadDoc(file) }}
          </div>
        </td>
      </tbody>
    </table>

    <table style="margin-bottom: 20px">
      <tbody>
        <tr>
          <th colspan="12" style="text-align: left; font-size: 16px">
            Pengalaman Kerja
          </th>
        </tr>
        <tr>
          <td colspan="12">
            <div class="textWrapper">
              <div class="title-pdf">Cerita tentang diri anda</div>
              <div class="text-pdf">
                {{ dataPdf.pengalaman_kerja_cerita }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-for="(p, i) in dataPdf.pengalaman_kerja" :key="i">
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Nama Perusahaan</div>
              <div class="text-pdf">{{ p.nama_perusahaan }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Posisi Terakhir</div>
              <div class="text-pdf">{{ p.posisi_terakhir }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Masuk</div>
              <div class="text-pdf">{{ p.tahun_masuk }}</div>
            </div>
          </td>
          <td colspan="6">
            <div class="textWrapper">
              <div class="title-pdf">Tahun Keluar</div>
              <div class="text-pdf">{{ p.tahun_keluar }}</div>
            </div>
          </td>
        </tr>
        <tr>
          <div v-for="(file, index) in p.dokumen" :key="index">
            {{ downloadDoc(file) }}
          </div>
        </tr>
      </tbody>
      <tbody>
        <tr>
          <td colspan="12">
            <div
              v-for="(file, index) in dataPdf.non_formal.dokumen"
              :key="index"
            >
              {{ downloadDoc(file) }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    dataPdf: Object
  },
  computed: {
    schoolDetail() {
      return this.$store.getters.g_school;
    }
  },
  methods: {
    fileNameFromUrl(url) {
      let filename = url.substring(
        url.lastIndexOf("/") + 1,
        url.lastIndexOf(".")
      );
      if (filename) {
        return filename;
      }
      return "";
    },
    downloadDoc(uri, name) {
      let filename = name;
      if (!name) {
        filename = this.fileNameFromUrl(uri);
      }
      let link = document.createElement("a");
      link.download = filename;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      link = null;
    }
  }
};
</script>

<style lang="scss">
#pdfDownloadContainerStaff {
  @mixin centerElement {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }

  font-size: 12px;
  table,
  th,
  td {
    border: 1px solid black;
  }

  th,
  td {
    padding: 7px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  #headerDocStaff {
    table,
    th,
    td {
      border: none;
    }
  }

  .reportCardHeader {
    .reportCardLogo {
      font-size: 18px;
      font-weight: bold;
      @include centerElement;
    }
  }

  .reportCardFooter {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .textWrapper {
    .title-pdf {
      font-size: 10px;
      font-weight: 500;
    }
    .text-pdf {
      font-size: 12px;
      font-weight: 700;
    }
  }
}
</style>
