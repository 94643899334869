<template>
  <div>
    <v-row class="py-3" no-gutters align="center">
      <v-select
        v-model="initType"
        :items="filterType"
        item-value="type"
        item-text="label"
        :class="
          !$vuetify.breakpoint.smAndDown
            ? 'mr-2 select-150 d-inline-block'
            : 'mr-2 mb-3'
        "
        :label="$t('app.type')"
        outlined
        hide-details
        dense
        @change="filterStatus()"
      />
      <v-btn
        class="gradient-primary caption mr-2"
        dark
        dense
        depressed
        @click="$store.commit('TOGGLE_DIALOG_CALON_SISWA')"
      >
        <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">
          {{ $t("app.add") }} {{ getMenuName() }}
        </span>
        <v-icon>mdi-plus-box</v-icon>
      </v-btn>
      <v-btn
        :loading="loadingDownloadExcel"
        :disabled="dataTable.length === 0"
        class="gradient-primary mr-2 caption"
        dark
        depressed
      >
        <download-excel
          :fetch="downloadExcel"
          :fields="fields"
          :name="`${setExcelDownloadName()}.xls`"
          class="d-inline"
        >
          <span v-if="$vuetify.breakpoint.smAndUp" class="mr-2">{{
            $t("app.download")
          }}</span>
          <v-icon>mdi-cloud-download</v-icon>
        </download-excel>
      </v-btn>
      <div class="subtitle-2 font-weight-bold d-inline">
        {{ total }}
        {{ getMenuName() }}
      </div>
      <v-spacer></v-spacer>
      <v-row no-gutters justify="end">
        <v-text-field
          v-model="queryData.search"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          hide-details
          style="max-width: 250px"
          @keydown="searchEmployee()"
        />
        <v-btn
          v-if="multipleId.length > 0"
          icon
          class="primary white--text ml-3"
          @click="setMutation(null)"
        >
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </v-row>
    </v-row>

    <v-data-table
      v-model="multipleId"
      :headers="tableHeaders"
      :items="dataTable"
      :page.sync="queryData.page"
      :items-per-page="queryData.limit"
      :loading="loading"
      :options.sync="options"
      item-key="id"
      hide-default-footer
      show-select
      disable-pagination
      class="elevation-0 table-fixed"
    >
      <template v-slot:item.nip="{ item }">
        <v-edit-dialog
          :return-value.sync="item.nip"
          large
          persistent
          @save="saveNIP(item.id, item.nip)"
        >
          <v-btn v-if="item.nip" color="primary" text>{{ item.nip }}</v-btn>
          <v-btn v-else color="warning" text>{{
            $t("master_data.staff.input_nip")
          }}</v-btn>
          <template v-slot:input>
            <v-text-field
              v-model="item.nip"
              :rules="[v => !!v || $t('app.required')]"
              single-line
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.status="{ item }">
        <v-edit-dialog
          :return-value.sync="item.status"
          large
          persistent
          @save="saveStatus(item.id, item)"
        >
          <v-btn v-if="item.status" color="primary" text>{{
            setStatusLabel(item)
          }}</v-btn>
          <v-btn v-else color="warning" text>{{
            $t("master_data.staff.input_status")
          }}</v-btn>
          <template v-slot:input>
            <v-select
              v-model="item.status"
              :label="$t('master_data.staff.select_status')"
              :rules="[v => !!v || $t('app.required')]"
              :items="statusList"
              item-value="label"
              item-text="label"
              single-line
            >
            </v-select>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.position="{ item }">
        <v-edit-dialog
          :return-value.sync="
            modelPosition[dataTable.findIndex(d => d.id == item.id)].idPosition
          "
          large
          persistent
          @save="
            savePosition(
              item.id,
              modelPosition[dataTable.findIndex(d => d.id == item.id)]
                .idPosition
            )
          "
        >
          <v-btn v-if="item.data_position.length > 1" color="primary" text>
            <div>
              <span class="mr-2" small>{{
                item.data_position[0].position
              }}</span>
              <span
                v-if="item.data_position.length > 1"
                class="grey--text caption"
                >(+{{ item.data_position.length - 1 }}
                {{ $t("app.others") }})</span
              >
            </div>
          </v-btn>
          <v-btn
            v-else-if="item.data_position.length == 1"
            color="primary"
            text
            >{{ item.data_position[0].position }}</v-btn
          >
          <v-btn v-else color="warning" text>{{
            $t("master_data.select_position")
          }}</v-btn>
          <template v-slot:input>
            <v-autocomplete
              v-model="
                modelPosition[dataTable.findIndex(d => d.id == item.id)]
                  .idPosition
              "
              ref="selectPosition"
              :items="dataPosition"
              :label="$t('master_data.select_position')"
              :rules="[v => !!v || $t('app.required')]"
              item-text="position"
              item-value="id"
              multiple
              deletable-chips
              small-chips
              single-line
              style="max-width:300px"
            >
              <!-- <template v-slot:prepend-item>
                  <v-row class="mx-2 mb-2">
                    <v-btn
                      text
                      small
                      block
                      class="primary--text"
                      @click="$refs.selectPosition.blur()"
                      >OK</v-btn
                    >
                  </v-row>
                </template> -->
            </v-autocomplete>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.download="{ item }">
        <v-tooltip left>
          <template v-slot:activator="{ on }">
            <v-btn
              color="success"
              class="mr-1"
              icon
              v-on="on"
              @click="downloadPdf(item)"
            >
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("master_data.download_student_detail") }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-menu transition="slide-x-transition" bottom right rounded="lg">
          <template #activator="{ on, attrs }">
            <v-btn fab small depressed icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="changePassword(item)">
              <v-list-item-title>
                <v-icon class="mr-2" color="primary">mdi-lock</v-icon>
                {{ $t("master_data.student.change_password") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="toDetail(item)">
              <v-list-item-title>
                <v-icon class="mr-2" color="primary"
                  >mdi-square-edit-outline</v-icon
                >
                {{ $t("master_data.detail") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item
              :disabled="multipleId.length > 0"
              @click="setMutation(item)"
            >
              <v-list-item-title>
                <v-icon
                  class="mr-2"
                  :color="multipleId.length == 0 && 'primary'"
                  >mdi-login-variant</v-icon
                >
                {{ $t("master_data.mutation") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <Pagination
      :length="length"
      :total="total"
      :current-page="queryData.page"
      :limit="queryData.limit"
      :handler="paginateChange"
    />

    <AddMutation
      :type="type"
      :isOpen="modalMutation"
      :handler="type => closeMutation(type)"
      :data="selectedDataMutation"
    />

    <v-dialog v-model="changePasswordModal" width="500">
      <v-card>
        <v-form v-model="formPassword" ref="formPassword">
          <v-card-title>
            {{ $t("master_data.student.change_password") }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field
              v-on:keyup.enter="saveChangePassword()"
              v-model="password.new_password"
              :label="$t('app.password_new')"
              :append-icon="newPass ? 'mdi-eye' : 'mdi-eye-off'"
              :type="newPass ? 'text' : 'password'"
              :rules="[v => !!v || $t('app.required')]"
              outlined
              dense
              @click:append="newPass = !newPass"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              outlined
              text
              @click="changePasswordModal = false"
            >
              {{ $t("app.cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              :loading="loadingChangePassword"
              @click="saveChangePassword()"
            >
              {{ $t("app.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <vue-html2pdf
      ref="html2Pdf"
      :htmlToPdfOptions="pdfOptions()"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-content-width="97%"
      class="documentDownload"
      @progress="onProgress($event)"
    >
      <section slot="pdf-content">
        <StaffDetailDownload :dataPdf="dataPdf" :type="type" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import AddMutation from "../components/AddMutation";
import StaffDetailDownload from "./StaffDetailDownload";
import VueHtml2pdf from "vue-html2pdf";

import {
  getEmployee,
  updateStatus,
  updateNIP,
  updatePosition
} from "@/api/admin/master/employee";
import { updatePasswordByAdmin } from "@/api/admin/admin";
import { getPosition } from "@/api/masterData";
import { downloadPersonDetail } from "@/api/admin/master";

import { mapGetters } from "vuex";
import i18n from "@/i18n";

let typingTimer;
const doneTypingInterval = 750;

import moment from "moment/moment";

export default {
  computed: {
    ...mapGetters(["g_language"])
  },
  props: { type: String },
  components: {
    Pagination: () => import("@/components/Pagination"),
    AddMutation,
    StaffDetailDownload,
    VueHtml2pdf
  },
  data() {
    return {
      statusName: i18n.t("app.all"),
      modalMutation: false,
      selectedDataMutation: [],
      loadingDownloadExcel: false,
      multipleId: [],
      filterType: [
        { type: "ALL", label: i18n.t("app.all").toUpperCase() },
        {
          type: "GURUTETAP",
          label: i18n.t("master_data.permanent_teacher").toUpperCase()
        },
        {
          type: "HONORGTT",
          label: i18n.t("master_data.gtt_honor").toUpperCase()
        },
        {
          type: "HONORKOMITE",
          label: i18n.t("master_data.committee_honor").toUpperCase()
        },
        {
          type: "GURUTETAPYAYASAN",
          label: i18n
            .t("master_data.foundation_permanent_teacher")
            .toUpperCase()
        }
      ],
      statusList: [
        {
          type: "GURUTETAP",
          label: i18n.t("master_data.permanent_teacher").toUpperCase()
        },
        {
          type: "HONORGTT",
          label: i18n.t("master_data.gtt_honor").toUpperCase()
        },
        {
          type: "HONORKOMITE",
          label: i18n.t("master_data.committee_honor").toUpperCase()
        },
        {
          type: "GURUTETAPYAYASAN",
          label: i18n
            .t("master_data.foundation_permanent_teacher")
            .toUpperCase()
        }
      ],
      loading: false,
      initType: "ALL",
      total: 0,
      length: 0,
      options: {},
      queryData: {
        page: 1,
        limit: 10,
        sort: [],
        order: "DESC",
        search: "",
        institution: "",
        filter: {
          "employees.type": [this.type],
          "employees.status": []
        }
      },
      fields: {
        [i18n.t("app.name")]: "name",
        [i18n.t("app.username")]: {
          field: "username",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("app.nip")]: {
          field: "nip",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.table.phone")]: {
          field: "phone",
          callback: value => this.preprocessLongNum(value)
        },
        [i18n.t("master_data.table.position")]: {
          field: "data_position",
          callback: value => value.map(d => d.position).join(" | ")
        },
        Status: "status"
      },
      tableHeaders: [
        {
          text: i18n.t("master_data.table.name"),
          align: "left",
          value: "name"
        },
        { text: "Username", value: "username" },
        { text: i18n.t("app.nip"), value: "nip" },
        {
          text: i18n.t("master_data.table.phone"),
          value: "phone",
          sortable: false
        },
        {
          text: i18n.t("master_data.table.position"),
          value: "position"
        },
        { text: "Status", value: "status" },
        // {
        //   text: i18n.t("app.download"),
        //   value: "download"
        // },
        {
          text: i18n.t("app.action"),
          sortable: false,
          align: "center",
          value: "actions",
          width: 80
        }
      ],
      dataTable: [],
      allStatus: [],
      dataPosition: [],
      modelPosition: {},
      password: {
        person: null,
        new_password: ""
      },
      loadingChangePassword: false,
      newPass: true,
      changePasswordModal: false,
      formPassword: false,
      dataPdf: null
    };
  },
  watch: {
    "$route.query.tab": {
      immediate: true,
      handler() {
        if (this.$route.query.tab == 0 || !this.$route.query.tab)
          this.filterStatus();
      }
    },
    options(newVal) {
      if (newVal) {
        this.queryData.sort = newVal.sortBy;
        this.getEmployee();
      }
    },
    queryData: {
      immediate: true,
      handler() {
        if (this.$route.query.tab == 0 || !this.$route.query.tab)
          this.getEmployee();
      }
    }
  },
  created() {
    getPosition().then(d => (this.dataPosition = d.data.data));
  },
  methods: {
    setExcelDownloadName() {
      moment.locale(this.g_language);
      const now = moment().format("DD-MM-YYYY, HH_mm_ss");
      const setStatusName =
        this.statusName.substring(0, 1).toUpperCase() +
        this.statusName.substring(1).toLowerCase();

      if (this.g_language === "id") {
        return `${setStatusName} ${this.getMenuName()} - ${now}`;
      } else {
        return `${this.getMenuName()} ${setStatusName} - ${now}`;
      }
    },
    preprocessLongNum(value) {
      if (String(value).startsWith("0x")) {
        return value;
      }
      if (!isNaN(value) && value != "") {
        return `="${value}"`;
      }
      return value;
    },
    setStatusLabel(item) {
      if (this.filterType.find(r => r.type == item.status)) {
        return this.filterType.find(r => r.type == item.status).label;
      } else return "";
    },
    async downloadPdfStudent(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      downloadPersonDetail({ person: item.person })
        .then(res => {
          if (res.data.code) {
            window.open(res.data.data.path);
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: res.data.message,
              color: "error"
            });
          }
          this.$store.commit("TOGGLE_FULL_LOADING");
        })
        .catch(() => this.$store.commit("TOGGLE_FULL_LOADING"));
    },
    onProgress(e) {
      if (e == 100) this.$store.commit("TOGGLE_FULL_LOADING");
    },
    async downloadPdf(item) {
      this.$store.commit("TOGGLE_FULL_LOADING");
      this.selectedDownloadPdf = item;
      // const detailReport = await getApplicantByID({ id: item.id });
      // this.dataPdf = detailReport.data.data;

      setTimeout(async () => {
        await this.$refs.html2Pdf.generatePdf();
        this.selectedDownloadPdf = null;
        this.dataPdf = null;
      }, 100);
    },
    pdfOptions() {
      const filename = this.selectedDownloadPdf
        ? `${this.selectedDownloadPdf.name}-${this.selectedDownloadPdf.nis}.pdf`
        : "";

      return {
        margin: 0.3,
        filename: filename,
        image: {
          type: "jpeg",
          quality: 0.98
        },
        enableLinks: false,
        html2canvas: {
          scale: 1,
          bottom: 20,
          useCORS: true
        },
        jsPDF: {
          unit: "cm",
          format: "a4",
          orientation: "portrait"
        }
      };
    },
    saveChangePassword() {
      if (this.$refs.formPassword.validate()) {
        this.loadingChangePassword = true;
        updatePasswordByAdmin(this.password)
          .then(res => {
            if (res.data.code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: i18n.t("app.change_password_success"),
                color: "success"
              });
              this.changePasswordModal = false;
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: res.data.message,
                color: "error"
              });
            }
            this.loadingChangePassword = false;
          })
          .catch(() => (this.loadingChangePassword = false));
      }
    },
    changePassword(item) {
      this.password = {
        person: null,
        new_password: ""
      };
      this.$nextTick(() => {
        this.changePasswordModal = true;
        this.password.person = item.person;
      });
    },
    searchEmployee() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.queryData.page = 1;
        this.getEmployee();
      }, doneTypingInterval);
    },
    async downloadExcel() {
      this.loadingDownloadExcel = true;
      const query = JSON.parse(JSON.stringify(this.queryData));
      query.limit = this.total;
      query.page = 1;

      const res = await getEmployee(query);
      this.loadingDownloadExcel = false;
      if (res.data.code) {
        if (res.data.data.data.length == 0) {
          this.$store.commit("CALL_SNACKBAR", {
            msg: this.$i18n.t("app.data_not_found"),
            color: "error"
          });
        } else return res.data.data.data;
      }
    },
    async savePosition(idEmployee, idPosition) {
      await updatePosition({ id: idEmployee, position: idPosition }).catch(
        err => {
          if (err) this.loading = false;
        }
      );
      this.getEmployee();
    },
    async saveStatus(idEmployee, idStatus) {
      const getStatus = this.filterType.find(
        filter => filter.label === idStatus.status
      ).type;
      await updateStatus({ id: idEmployee, status: getStatus }).catch(err => {
        if (err) this.loading = false;
      });
      this.getEmployee();
    },
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
      this.loadingSave = false;
    },
    async saveNIP(idEmployee, idNIP) {
      this.loading = true;
      try {
        const response = await updateNIP({ id: idEmployee, nip: idNIP });
        if (response.data.code) {
          this.snackBar(false, response.data.message);
        } else {
          this.snackBar(true, response.data.message);
        }
      } catch (error) {
        this.snackBar(true, error);
      }
      this.loading = false;
      this.getEmployee();
    },
    setMutation(data) {
      if (!data || data == null) {
        this.selectedDataMutation = this.multipleId;
        this.modalMutation = true;
      } else {
        this.selectedDataMutation = [{ ...data }];
        this.modalMutation = true;
      }
    },
    toDetail(item) {
      this.$router.push({
        path: `${this.$route.name.toLowerCase()}-manual`,
        query: { id: item.id }
      });
    },
    closeMutation(type) {
      this.modalMutation = false;
      this.multipleId = [];
      if (type == "save") this.getEmployee();
    },
    paginateChange(page, limit) {
      this.queryData.page = page;
      this.queryData.limit = limit;
      this.filterStatus();
    },
    filterStatus() {
      this.queryData.filter["employees.status"] =
        this.initType == "ALL" ? [] : [this.initType];
      this.dataTable = [];
      this.statusName = this.filterType.find(
        filter => filter.type === this.initType
      ).label;
      this.getEmployee();
    },
    listAction() {
      const menuAction = [
        { name: "Detail", path: this.$route.name.toLowerCase() + "-manual" },
        {
          name: this.$i18n.t("master_data.teacher.mutation"),
          path: ""
        },
        {
          name: this.$i18n.t("master_data.teacher.change_type"),
          path: ""
        }
      ];
      return menuAction;
    },
    getMenuName() {
      return this.$route.name == "Teacher"
        ? this.$i18n.t("routes.teacher")
        : this.$i18n.t("routes.staff");
    },
    getEmployee() {
      this.dataTable = [];
      this.total = 0;
      this.loading = true;
      this.modelPosition = {};
      getEmployee(this.queryData).then(res => {
        const r = res.data.data;
        this.dataTable = r.data;
        this.modelPosition = r.data.map(d => ({
          idEmployee: d.id,
          idPosition: d.data_position.map(d => d.id)
        }));
        this.length = r.last_page;
        this.total = r.total;
        this.loading = false;
      });
    },
    getCurrentPage(val) {
      this.queryData.page = val;
    },
    getPerPage(val) {
      this.perPage = val;
    }
  }
};
</script>
