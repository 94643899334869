import request from "@/utils/request";

export function getEmployee(data) {
  return request({
    method: "post",
    url: "employee/list",
    data: data
  });
}

export function getByID(data) {
  return request({
    method: "post",
    url: "employee/get_byid",
    data: data
  });
}

export function employeeUpdate(data) {
  return request({
    method: "post",
    url: "employee/update_employee",
    data: data
  });
}

export function updateStatus(data) {
  return request({
    method: "post",
    url: "employee/update_status",
    data: data
  });
}

export function updateNIP(data) {
  return request({
    method: "post",
    url: "employee/update_nip",
    data: data
  });
}

export function updatePosition(data) {
  return request({
    method: "post",
    url: "employee/employee-position",
    data: data
  });
}

export function deleteEmployeesMutation(data) {
  return request({
    method: "post",
    url: "employee/permanent_delete_employee",
    data: data
  });
}
